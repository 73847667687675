import { list } from '@wix/ambassador-identity-members-groups-v1-group-info/http';
import { IHttpClient } from '@wix/yoshi-flow-editor';
import { isTruthy } from '../utils/isTruthy';

export class GroupsApi {
  constructor(private httpClient: IHttpClient) {}

  public async getMissingPlanIds(pageId: string) {
    const response = await this.httpClient.request(
      list({
        type: 'plan',
        resourceId: pageId,
      }),
    );
    const { groups = [] } = response.data;
    return groups.map((group) => group.id).filter(isTruthy);
  }
}

export function createGroupsApi(httpClient: IHttpClient) {
  return new GroupsApi(httpClient);
}
