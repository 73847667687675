export function memoize<T, P extends any[] = any[]>(
  fn: (...args: P) => T,
  compare: (a: P, b: P) => boolean = arraysEqual,
) {
  let call: [P, T] | undefined;

  return (...newArgs: P): T => {
    if (!call || !compare(call[0], newArgs)) {
      call = [newArgs, fn(...newArgs)];
    }
    return call[1];
  };
}

function arraysEqual(xs: any[], ys: any[]) {
  if (xs.length !== ys.length) {
    return false;
  }
  for (let i = 0; i < xs.length; i++) {
    if (xs[i] !== ys[i]) {
      return false;
    }
  }
  return true;
}

export function swapArrayElements<T extends unknown[]>(array: T, indexA: number, indexB: number): T {
  const shallowCopy = array.slice() as T;
  [shallowCopy[indexA], shallowCopy[indexB]] = [shallowCopy[indexB], shallowCopy[indexA]];
  return shallowCopy;
}
