import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { ISettingsContextValue, ISettingsValue } from '@wix/tpa-settings';
import { HIGHLIGHTED_MOCK_PLAN_ID } from '../../fixtures';
import { compressUuidArray, uncompressUuidArray } from '../../services/uuid-compression';
import { isTruthy } from '../../utils/isTruthy';
import { PackagePickerSettingsParams } from './settingsParams';

export type ReadSettings = Pick<ISettingsContextValue, 'get'>;
export type WriteSettings = Pick<ISettingsContextValue, 'set'>;
export type ReadWriteSettings = ReadSettings & WriteSettings;
type CompressedUuidSettings = 'hiddenPlans' | 'visiblePlans' | 'planOrder';

export class SettingsReader {
  constructor(public settings: ReadSettings, public params: PackagePickerSettingsParams) {}

  get<K extends keyof PackagePickerSettingsParams>(key: K) {
    return this.settings.get(this.params[key]);
  }

  asArray<K extends keyof PackagePickerSettingsParams>(key: K): string[] {
    return (this.settings.get(this.params[key]) as string).split(',').filter(isTruthy);
  }

  uuidAsArray<K extends CompressedUuidSettings>(key: K): string[] {
    return uncompressUuidArray(this.asArray(key));
  }
}

class SettingsReaderWriter extends SettingsReader {
  constructor(public settings: ReadWriteSettings, public params: PackagePickerSettingsParams) {
    super(settings, params);
  }

  set<K extends keyof PackagePickerSettingsParams>(key: K, value: ISettingsValue<PackagePickerSettingsParams[K]>) {
    return this.settings.set(this.params[key], value);
  }

  setUuidArray<K extends CompressedUuidSettings>(key: K, value: string[]) {
    const compressedValue = compressUuidArray(value).join(',') as ISettingsValue<PackagePickerSettingsParams[K]>;
    return this.set(key, compressedValue);
  }
}

export class DefaultSettingsAdapter extends SettingsReaderWriter {
  constructor(public settings: ReadWriteSettings, public params: PackagePickerSettingsParams) {
    super(settings, params);
  }

  getHighlightedPlanId(plans: PublicPlan[], showDemoHighlight?: boolean) {
    const showHighlightedPlan = this.get('showHighlightedPlan');
    const highlightedPlanId = this.get('highlightedPlan');

    const primaryPlanId = plans.find((plan) => plan.primary)?.id;

    if (showHighlightedPlan && highlightedPlanId) {
      return highlightedPlanId;
    }

    if (showHighlightedPlan && !highlightedPlanId && primaryPlanId) {
      return primaryPlanId;
    }

    return (
      plans.find((p) => p.id === HIGHLIGHTED_MOCK_PLAN_ID)?.id ||
      (showDemoHighlight && (plans[1]?.id || plans[0]?.id)) ||
      ''
    );
  }

  hidePlan(id: string) {
    const hiddenPlans = this.uuidAsArray('hiddenPlans');
    this.setUuidArray('hiddenPlans', hiddenPlans.concat(id));
  }

  unhidePlan(id: string) {
    const hiddenPlans = this.uuidAsArray('hiddenPlans').filter((hiddenId) => id !== hiddenId);
    this.setUuidArray('hiddenPlans', hiddenPlans);
  }

  runMigrations(_plans: PublicPlan[]) {}

  /**
   * Method is overwritten in PackagePickerSettingsAdapter.
   * Default implementation does nothing
   */
  async clearPrimaryPlan(plans: PublicPlan[]): Promise<PublicPlan[]> {
    return plans;
  }

  setHighlightedPlan(plans: PublicPlan[], id: string) {
    this.set('highlightedPlan', plans.find((plan) => plan.id === id)?.id ?? '');
  }
}
