export interface IEvents {
  tabState: TabState;
  areSettingsOpened: boolean;
  reset: undefined;
}

export const SETTINGS_EVENTS: { [index: string]: keyof IEvents } = {
  ARE_SETTINGS_OPENED: 'areSettingsOpened',
  TAB_STATE: 'tabState',
  RESET: 'reset',
};

export enum TabState {
  REGULAR = 'regular',
  HIGHLIGHTED = 'highlighted',
}
