export enum Alignment {
  LEFT = 0,
  CENTER = 1,
  RIGHT = 2,
}

export enum AlignmentName {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export function alignmentName(value: Alignment): AlignmentName {
  return alignmentMap[value];
}

const alignmentMap = {
  [Alignment.LEFT]: AlignmentName.LEFT,
  [Alignment.CENTER]: AlignmentName.CENTER,
  [Alignment.RIGHT]: AlignmentName.RIGHT,
};
